import * as React from "react"
import { Link } from "gatsby"
import { FiAlertTriangle } from "react-icons/fi"

const NotFoundPage = () => {
  return (
    <div className="flex flex-col justify-center items-center text-red bg-red bg-opacity-10 h-screen">
      <FiAlertTriangle className="h-32 w-32 mb-5 box-border" style={{ strokeWidth: 1 }} />
      <div className="font-medium text-6xl">404</div>
      <div className="font-thin text-4xl">La page demandée n'existe pas.</div>
    </div>
  )
}

export default NotFoundPage